.its-login--logo {
    padding-bottom: 3em;
}

.its-login--register-logo {
    margin: 2em;
    width: 200px;
    height: 200px;
}

.its-login--forgot-pass {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.54);
}
