.its-content {
    background: #fff;
    margin: 32px;
}

//TODO SIDEMENU COMPONENT SHOULD USE CORRECT BRAND PRIMARY COLOR BASED ON PRODUCT.

.its-sidemenu .md-button.active,
.its-sidemenu .md-button.active md-icon {
    color: $PrimaryColour;
}

.its-ng-message {
    color: rgb(221, 44, 0);
    display: flex;
    justify-content: center;
}

.its-check-left-nav {
    position: fixed;
    top: 48px;
    width: 320px;
    overflow: scroll;
}

.its-check-content-area {
    margin-left: 320px;
    padding: 20px;
}

.its-input-subtext {
    @extend .its-text--caption;
}

.its-upgrade-message-container {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #eee;

    .its-upgrade-message-container--text {
        font-style: italic;
        opacity: 0.7;
    }
}

.its-content-nav-footer {
    border-top: 1px solid $BorderColour;
    height: 40px;
    background: white;
    padding-right: 10px;
    padding-left: 10px;

    md-icon {
        @extend .its-clickable !optional;
    }
}
