.its-translation--container {
    margin: 85px 20px 0;
}

.its-translation--section {
    background-color: #fff;
}

.its-translation-english-label {
    margin-top: 34px;
}
