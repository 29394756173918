// I THINK THIS WHOLE FILE CAN BE DELETED.

.its-image--container {
    border: dashed #ccc 2px;
    padding: 50px;
    min-width: 150px;
}

.its-image--button-icon {
    margin: 0 5px 3px 0;
}

.its-image--full-width {
    width: 100%;
}

// This is the only class that is being used that I could find.
.its-image--image {
    margin-left: 10px;
    width: 98%;
    height: auto;
    margin-bottom: 20px;
}

.its-image--add-image-container {
    border: dashed #eee 2px;
    height: 100px;
    width: 100px;
    border-radius: 5px;
    background-color: #fafafa;
    background-size: contain;
    margin: 0;
    overflow: hidden;

    .its-button {
        height: 100px;
        width: 100px;
        padding: 0;
        margin: 0;

        md-icon {
            font-size: 35px;
            color: #ddd;
            margin-left: -17px;
            margin-top: -10px;
        }
    }

    .its-clear-image {
        margin-top: 0;
        margin-right: 0;
        background-color: rgba(255, 255, 255, 0.87);
        cursor: pointer;
    }

    .md-button:hover {
        background-color: #f6f6f6;
    }
}
