.its-checklist--list {
    .dndPlaceholder {
        padding: 60px;
        border: 2px dashed $BorderColour;
        list-style: none;
        background-color: $LightGrey;
    }
    .dndDraggingSource {
        display: none;
    }
}

.its-sections {
    .dndPlaceholder {
        margin: 0 $Margin $Margin;
        height: 40px;
        padding: 0;
        border-radius: $BorderRadius;
        border: 2px dashed $BorderColour;
        list-style: none;
        background-color: $LightGrey;
    }
    .dndDraggingSource {
        display: none;
    }
}

.its-checklist-item-error-message {
    @extend .its-text--caption;
    @extend .its-text__danger;
}

md-toolbar.its-secondary-toolbar:not(.md-menu-toolbar) md-icon.its-inline-edit-icon {
    color: rgba(0, 0, 0, 0.54);
}

md-toolbar:not(.md-menu-toolbar) {
    color: $FontColour;
}

.its-checklist-item--preview {
    margin: 0 20px 0 5px;
}

.its-item--edit-overlay {
    position: fixed;
    background: black;
    top: 109px;
    opacity: 0.15;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.its-inline-edit-icon {
    @include md-icon-size(16px);
    margin-top: -5px;
}

.its-checklist--list-item-not-admin {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: default !important;
}

.its-checklist--button-disable .md-button:hover {
    background-color: transparent;
}

.its-checklist-multiplechoice-response {
    background-color: #f9f9f9;
    margin-bottom: 10px;
    border-left: 2px solid $primaryCheckColour;
    padding: 0 10px;

    md-icon {
        margin-top: 25px;
        cursor: pointer;
    }
}

.its-checklists-error-messaging {
    margin-top: 85px;
}

.its-checklist--list .disabled {
    opacity: 0.6;
    background-color: #f6f6f6;
}

.its-checklist--list-item {
    background: $InputBG;

    &.its-checklist--list-item__editing {
        @extend .its-editing-frame;
        border-left: 4px solid $CheckColour_Primary;
        position: relative;
        background: white;
        z-index: 2;
    }
}

.its-editing-frame {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
}

.its-checklist--list-hover-actions .md-icon-button {
    padding: 0;
    margin: 0;
}

.its-checklist--list-actions {
    margin-right: 8px;
}

.its-checklist--list-hover-actions {
    @media (max-width: 960px) {
        display: inline;
    }
}

.its-assign-checklist-modal--date-picker {
    padding-right: 10px;

    & > div {
        display: none;
    }
}

.its-checklist--time {
    padding-right: 10px;
}

.its-checklist--button {
    @extend .its-btn--secondary;
    @include md-icon-size(24px);
    min-width: 52px;
    min-height: 52px;
    padding: 10px;
}

.its-checklist--button-action {
    padding: 10px 25px;
    border-radius: 0;
}

.its-checklist--button-display-text {
    width: 165px;
}

.its-checklist--list-example {
    display: inline;
    pointer-events: none;
    touch-action: none;
    opacity: 0.65;
}

.its-checklist--list-title {
    @extend .its-text--title;
    margin-top: 12px;
    padding-left: 10px;
}

.its-checklist--list-title-requirements {
    @extend .its-text--caption;
    @extend .its-text__danger;
}

.its-checklist--complete-by-date-error {
    @extend .its-text--caption;
    @extend .its-text__danger;
    margin-left: 66px;
    margin-top: -20px;
}

.its-checklist--list-footer-no-icons {
    margin-top: -30px;
    height: 60px;
}

.its-checklist--list-footer {
    @extend .its-text--caption;
    margin-bottom: auto;
    margin-left: 10px;
}

.its-checklist--list-footer-divider {
    margin-right: 30px;
    font-size: 1.5em;
    opacity: 0.3;
}

.its-checklist--list-footer-item {
    margin-right: 28px;
}

.its-checklist---list-footer-display-text {
    display: contents;
    overflow: hidden;
    padding-left: 10px;
}

.its-checklist-edit-groups--section {
    padding: 0;
    margin: 0;

    .its-checklist-edit-groups--section--btn {
        box-shadow: none;
        background: #fff;
        margin: 5px;
        padding: 10px 15px;
    }

    .its-checklist-edit-groups--section--btn:hover {
        background: #eee;
        cursor: move;
    }

    .its-checklist-edit-groups--section--icon {
        padding-right: 20px;
        margin: 0;

        @media (max-width: 960px) {
            margin-left: 10px;
        }
    }
}

.its-checklist-edit-groups--new-section--icon {
    margin-right: 20px;
    @media (max-width: 960px) {
        margin-left: 10px;
    }
}

.its-checklist-edit-groups--new-section--btn {
    box-shadow: none;
    background: #fff;
    margin: 5px;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
}

.its-display-text-render {
    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}

.its-checklist--list-footer-icon {
    margin-right: 5px;
    padding-left: 5px;
}

.its-checklist--list-footer-item-uppercase {
    @extend .its-text--subtitle;
    @extend .its-text__uppercase;
    margin-right: 30px;
}

.its-checklist--group-list {
    background: white;
}

.its-checklist--group-list-icon {
    opacity: 0.5;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.its-checklist--group-list md-toolbar .md-menu {
    margin: 0;
}

.its-checklist--group-list md-prev-button.md-disabled,
.its-checklist--group-list md-next-button.md-disabled {
    display: none;
}

.its-checklist--list md-toolbar {
    background-color: #f6f6f6;
    border-bottom: 1px solid $BorderColour;
}

ul[dnd-list],
ul[dnd-list] > li {
    position: relative;
}

.its-checklist--list .md-datepicker-input-container {
}

.its-checklist--list md-datepicker {
}

.its-checklist--list .md-button.md-icon-button {
}

.its-checklist--group-list .md-tab.md-disabled {
    color: rgba(0, 0, 0, 0.26) !important; /* bug in angular material */
}

.its-checklist--list-section-info {
    padding-top: 25px;
    text-align: right;
    padding-right: 20px;
    opacity: 0.5;

    @media (max-width: 600px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.its-checklist--left-nav {
    left: 65px;
    top: 113px;
    width: 500px;
    z-index: 1;

    @media (max-width: 960px) {
        left: 0;
        top: 105px;
        width: 100px;
        min-width: 100px;

        .md-button {
            min-width: 60px;
        }
    }
}

.its-checklist--list-section-content {
    z-index: 0;
    overflow-y: scroll;
}

.its-checklist--list-section-content-items {
}

.its-checklist-edit--add-section-icon {
    opacity: 0.5;
    margin-top: -2px;
    cursor: pointer;
}

.its-checklist-edit--add-section-tab {
    padding: 10px;
    margin-top: 5px;
    margin-left: 7px;
}

.its-checklist--check-box-vertical-view {
    display: block;
    margin-right: 0;
}

.its-checklist--check-box-dropdown {
    width: 140px;
}

.its-checklist--outcome-remove {
    padding-top: 26px;
    margin-right: 5px;
    margin-left: 5px;
}

.additional-info-modal {
    display: block;
}

.its-example-photo {
    display: block;

    its-file-picker {
        width: 100%;
        .md-button {
            margin: $Margin 0 0;
            width: 100%;
            background-color: $InputBG;
            min-height: 161px;
            border: 1px dashed $BorderColour;
            padding: 30px;
            //color: $FontColour_Muted;
        }
    }
}

.itc-checklist--instructions {
    margin: $Margin;

    .its-example-photo {
        max-width: 100%;
    }
}

.its-checklist--instructions-render {
    padding: $Padding_half;
    background: $LightGrey;
    max-height: 300px;
    overflow-y: scroll;
}

.itc-item-additional-information label {
    margin-bottom: 8px;
}

.its-checklist--scheduler-notification-group md-input-container {
    margin: 0 0 18px 0;
}

.its-checklist-select--range {
    margin-right: 15px;
}

.its-checklist-select--range-dropdown md-select-menu {
    margin-right: 15px;
}

.its-checklist--checkbox {
    margin-top: 10px;
    padding-right: 30px;
}

.its-checklist--score-info {
    @extend .its-text__muted;
    margin: 5px 0 20px 44px;

    @include media-breakpoint-down('xs') {
        margin: 5px 0 20px 0;
    }
}

.its-checklist--score-input {
    margin-bottom: 0;
    margin-left: 44px;
    max-width: 200px;
    @include media-breakpoint-down('xs') {
        margin-left: 0;
        max-width: 100%;
    }
}

.its-checklist--follow-up-due {
    padding-left: 30px;
    md-input-container {
        margin-top: 0;
    }
}

.its-checklist--selection-requirements {
    padding-left: 30px;
    margin-top: 10px;

    md-input-container {
        margin-right: 10px;
    }
}

.its-checklist--component-container {
    margin-top: 20px;
}

.its-checklist-pre-populated--center {
    text-align: center;
}

.its-checklist--followup-link {
    color: $primaryCheckColour;
    text-decoration: none;
}

.its-template-card--width {
    max-width: 254px;
    min-width: 254px;
}
