@import '~@fontsource/roboto/300';
@import '~@fontsource/roboto/400-italic';
@import '~@fontsource/roboto/400';
@import '~@fontsource/roboto/500';
@import '~@fontsource/roboto/700';
@import '~froala-editor/css/froala_editor.pkgd.min';
@import '~tributejs/dist/tribute.css';
@import '~material-design-icons/iconfont/material-icons';
@import '~angular-material/angular-material';

@import '~@intouch/essential-styles/source/config';
@import '~@intouch/its.check.essential/sass/config';
@import '~@intouch/its.essential/sass/config';
@import 'config';
@import '~@intouch/essential-styles/source/main';
@import '~@intouch/its.essential/sass/main';
@import '~@intouch/its.check.essential/sass/main';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import 'layout/its.tabs';
@import 'layout/its.form';
@import 'layout/its.panel';
@import 'layout/its.image';
@import 'layout/its.topbar';
@import 'layout/its.toolbar';
@import 'modules/its.froala-custom';
@import 'modules/its.login';
@import 'modules/its.checklist';
@import 'modules/its.audit';
@import 'modules/its.userpicker';
@import 'modules/its.locationlist';
@import 'modules/its.table';
@import 'modules/its.translation';
@import 'modules/its.file-process';
@import 'modules/its.settings';
@import 'global';
@import 'components/its.item.input.dialog.actions';
@import 'components/its.scoring.range';
@import 'components/its.md.color.picker';
@import 'components/its.skip.conditions';
@import 'modules/its.checklist.outcomes.modal';
@import 'overrites';
@import 'layout/its.print';
@import '~shepherd.js/dist/css/shepherd.css';
@import 'shepherd.overrides';
@import 'components/its.audit.progress.bar';
