.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
    background: $CheckColour_Primary !important;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background: $White !important;
    border-bottom: $Border;
    h3.shepherd-title {
        font-weight: 500;
    }
}

.shepherd-header {
    display: block !important;
}

.shepherd-text {
    font-size: 14px !important;
}

.shepherd-button {
    background: $CheckColour_Primary !important;
    font-weight: bold !important;
    padding: 12px 40px !important;

    &.shepherd-button-secondary {
        background: transparent !important;
        color: $CheckColour_Primary !important;
        font-weight: bold;
        margin-right: auto;
    }
}

.shepherd-width-medium {
    max-width: 500px !important;

    .shepherd-content {
        .shepherd-content-title {
            font-size: 30px;
        }

        .shepherd-content-text--centered {
            text-align: center;
        }

        .shepherd-content-text--medium {
            font-size: 16px;
        }

        .shepherd-text p {
            margin: 12px 0;
        }
    }
}
