.its-has-toolbar {
    margin-top: 50px;
}

.its-audit-view--item-footer {
    @extend .its-text__light;
    padding-bottom: 10px;
}

.its-audit-accept-reject {
    min-width: 360px;
    padding: 20px 20px 40px 20px;

    @media (min-width: 960px) {
        max-width: 360px;
    }

    @media (max-width: 959px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.its-audit-showing-previous-warning {
    @extend .its-text--caption;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $DangerColour;
    color: white;
}

/* styling for Audit settings searchable select for access groups */
.its-audit-settings-access-group {
    margin-right: 40px;
    margin-top: 40px;
}
