form {
    md-content {
        &.layout-padding {
            > * {
                padding-bottom: 4px;
            }
        }

        > div {
            &:first-of-type {
                md-input-container {
                    margin-top: 24px;
                }
            }

            &:last-of-type {
                md-input-container {
                    margin-bottom: 18px;
                }
            }

            md-input-container {
                margin-bottom: 0;
                margin-top: 10px;
            }
        }
    }

    /* needed due to a bug in material select overflow (https://github.com/angular/material/issues/6312) */
    .md-select-value *:first-child {
        flex: 1 1 auto;
    }
}
