.its-page-scroll {
    overflow: scroll;
}

@media (max-width: 960px) {
    #its-topbar {
        .its-toolbar {
            > button {
                min-width: 42px;
            }

            > div {
                overflow: hidden;

                h2 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                div[ng-if='vm.organizationLogoUrl'] {
                    display: none;
                }
            }
        }
    }

    .its-trial-banner {
        display: none;
    }
}

.its-trial-banner {
    position: fixed;
    z-index: 10000;
    top: 8px;
    left: 30%;
    background: #fff;
    border-radius: 5px;
    opacity: 0.8;
    padding: 8px 20px;
}

.its-trial-banner--button {
    padding-left: 5px;
}

.its-toolbar--responsive {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    min-height: 50px;
    box-sizing: border-box;

    .checklist-edit-settings {
        align-items: center;
        display: flex;
        padding-right: 8px;
    }
}
