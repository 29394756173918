.its-table-container {
    padding: 100px 20px 0;

    .its-table--pager-row {
        padding-bottom: 5px;
    }
}

.its-table-container-bottom-padding {
    @extend .its-table-container;
    padding-bottom: 80px;
}

.its-table-container-tabbed {
    @extend .its-table-container;

    padding: 85px 20px 0;
}

.its-table {
    margin-bottom: 20px;

    .its-table--paged {
    }

    .its-table--paged-no-header {
        margin-top: 190px;
    }
}

.its-table-empty-state-tabbed {
    margin-top: 115px;
}
