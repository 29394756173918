.its-md-color-picker {
    margin-right: 10px;
    md-input-container {
        display: none;
    }
    .md-color-picker-preview {
        transform: translateY(-24px);
    }
}

.md-color-picker-result > .md-color-picker-tabs {
    display: none;
}
