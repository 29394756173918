itc-audit-progress-bar {
    max-width: 120px;

    .its-audit-progress-bar--label {
        @extend .its-text--caption;
    }

    .its-audit-progress-bar--container-bar {
        height: 6px;
        background: $BorderColour;
        width: 100%;
        border-radius: 2px;

        .its-audit-progress-bar--fill-bar {
            width: 0;
            height: inherit;
            border-radius: inherit;
            background: $PrimaryColour;
        }
    }
}
