#its-topbar {
    position: relative;
}

.its-toolbar {
    width: 100%;
}

#its-content {
    padding-top: 0;
}

ul {
    padding-inline-start: 0px;
}

#its-sidebar {
    &::-webkit-scrollbar-thumb {
        background: $basePrimaryColour85;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $basePrimaryColour;
        }
    }
}

.its-contained-layout--container {
    .its-contained-layout--item {
        border-left: 3px solid $primaryCheckColour;

        .its-contained-layout--inner-container {
            border-left: 2px solid $primaryCheckColour;
        }
    }
}

@media screen and (max-width: 600px) {
    .its-contained-layout--container {
        .its-contained-layout--item {
            border-bottom: 2px solid $primaryCheckColour;
        }
    }
}

.hide-dndPlaceholder {
    .dndPlaceholder {
        display: none;
    }
}
