.its-panel {
    @include media-breakpoint-up(md) {
        max-width: 435px;
    }

    ul {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    .its-panel--survey-items-header {
        margin-left: 25px;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(sm) {
        .its-panel--survey-items-header {
            margin-left: 0px;
            text-align: center;
        }
    }

    .its-checklist--add-item-list-item {
        .its-checklist-edit-groups--section--btn {
            span {
                line-height: 24px;
            }
        }
    }

    .its-panel--menu-item {
        border-bottom: 1px solid #d3d3d3;
        width: 100%;

        i:first-child {
            width: 20%;
            padding: 25px;
        }

        span {
            position: relative;
            top: 5px;
            width: 80%;
            padding: 25px;
        }

        i:last-child {
            width: 10%;
            padding: 25px;
        }
    }

    .its-panel--menu-item:hover {
        background: #f6f6f6;
        cursor: pointer;
    }

    .its-panel--title {
        text-decoration: none;

        &:first-of-type {
            padding: 18px 25px 15px;
        }
        padding: 15px 25px;
        background: #fff;
        color: rgb(33, 33, 33);
        min-height: 55px;

        &.active {
            background-color: #f0f0f0;
        }

        &.clickable {
            cursor: pointer;
        }

        md-icon {
            padding-right: 10px;
        }
    }

    .its-panel--title-clickable {
        cursor: pointer;
        //transition: all ease-in 0.1s;
        &:hover {
            background-color: #fafafa;
        }
    }

    .its-panel--content {
        background-color: white;
        padding: 0 35px;
    }

    @include media-breakpoint-down(sm) {
        .its-panel--content {
            padding: 0px;
        }
    }
}
