.its-fileprocess-steps {
    .its-fileprocess-steps--step {
        display: inline-block;
        padding: 20px;
        .its-fileprocess-steps--step-num {
            display: inline-block;
            border-radius: 25px;
            width: 25px;
            height: 25px;
            background: #c3c3c3;
            border: 1px solid transparent;
            line-height: 25px;
            text-align: center;
            color: white;
            margin-right: 8px;
        }

        .its-fileprocess-steps--step-text {
            display: inline-block;
        }
    }

    .its-fileprocess-steps--step__active {
        .its-fileprocess-steps--step-num {
            background: $basePrimaryColour;
        }
    }
}

.its-fileprocess-status {
    @include md-icon-size(36px);
    display: block;
    position: relative;
    margin: 0;
}

.its-fileprocess__warning {
    color: $WarnColour;
}

.its-fileprocess__error {
    color: $DangerColour;
}

.its-fileprocess__successful {
    color: $PositiveColour;
}

.its-fileprocess-feedback-box {
    max-height: 300px;
    overflow-y: scroll;
}
